<template>
  <div>
    <div class="div-companies bg-white content-wrapper">
      <section class="content-header p-1 px-2">
        <div class="div-header container-fluid">
          <div style="border-bottom: 1px solid #0001" class="row mb-2">
            <div class="col-sm-12 d-flex justify-content-between align-items-center">
              <h4 style="color: #0007" class="mt-3">
                <i class="fas fa-graduation-cap" aria-hidden="true"></i>
                Academy
              </h4>

              <div>
                <router-link to="/admin/academy/create">
                  <button class="btn btn-sm btn-primary mr-2">
                    Novo Curso
                  </button>
                </router-link>
                <button class="btn btn-sm btn-outline-secondary" @click.prevent="$router.go(-1)">
                  <i class="fa fa-arrow-left mx-1"></i>Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="container-fluid">
        <div class="row">
          <div class="col-12" style="min-height: 40rem">
            <div class="card">
              <div class="d-flex justify-content-end p-2 m-1">
                <div style="width: 400px" class="search input-group input-group-sm">
                  <input
                      type="text"
                      v-model="inputSearch"
                      name="search_admin"
                      class="form-control"
                      placeholder="Pesquisar por curso ou plano"
                  >
                  <i v-if="!processing"
                      class="fas fa-search lupa-style search-admin"
                      style="z-index:10"
                      title="Perquisar Curso"
                  ></i>
                  <div v-show="processing" class="spinner-border search-admin" role="status" style="height: 1rem; width: 1rem; z-index:10" />
                </div>
              </div>
            </div>

            <small v-if="!totalCourses" class="d-flex justify-content-center">Não há cursos no momento.</small>

            <div v-show="!spinner" class="card card-custom" v-for="(course, index) in courses" :key="index"
                 title="Clique para mais informações.">
              <div class="d-flex justify-content-between align-items-center m-1 p-2">
                <div class="col-10">
                  <router-link :to="`/admin/academy/overview/${course._id}`" class="d-flex flex-wrap">
                    <div class="col-10">
                      <div class="d-flex flex-column text-dark">
                        <div class="d-flex flex-row flex-wrap">
                          <h4 class="font-weight-bold mr-2">{{ course.title }}</h4>
                          <div class="d-flex flex-row mb-1 mb-sm-0">
                            <div v-for="(plan, index3) in course.plans" :key="index3">
                              <span class='text-muted mr-2' style="font-size: .6rem">{{ translatePlans(plan) }}</span>
                            </div>
                          </div>
                        </div>
                        <p>{{ course.description }}</p>
                      </div>
                    </div>
                  </router-link>
                    <div class="tags">
                        <div class="d-flex flex-row align-items-center overflow-auto">
                          <div v-for="(tag, index2) in course.tags" :key="index2" class="tag">
                            <span class="button-tag">{{ tag }}</span>
                          </div>
                        </div>
                    </div>
                </div>

                <div class="col-2">
                  <!-- ativar curso -->
                  <label class="switch" :for="`activeInput-${index}`">
                    <input @change="toggleActive(course._id, $event)" type="checkbox" :id="`activeInput-${index}`"
                           :checked="course.active">
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            <!-- spinner esperando carregar -->
            <div :style="spinner ? '' : 'display: none !important'" class="d-flex justify-content-center align-items-center">
              <span class="spinner-grow" style="width: 5rem; height: 5rem;" role="status"></span>
            </div>
          </div>
        </div>

        <pagination
          v-if="totalCourses>limitPage"
          class="d-flex justify-content-center"
          :current="currentPage"
          :pages="totalPages"
          @setCurrentPage="setCurrentPage($event)" />
      </div>
    </div>
  </div>
</template>

<script>
import pagination from './components/Pagination.vue'
import * as lodash from 'lodash'
export default {
  components: { pagination },
  name: "index-academy",
  data() {
    return {
      courses: null,

      // paginação dinâmica
      currentPage: 1,
      limitPage: 4,

      // filtrar cursos
      // sortCourses: 'title',

      // busca por titulo ou plano
      inputSearch: '',
      processing: false,
      
      // variaveis com valores totais
      totalCourses: 0,

      // manipulacao spinner
      spinner: false,
    }
  },

  watch: {
    inputSearch: function(){
      this.processing = true
      this.debouncedGetAnswer()
    }
  },

  computed: {
    
    /**
     * total de paginas de acordo com a listagem
     */
    totalPages: function(){
      let page = (this.totalCourses + 1) === (this.limitPage + 1) ? 1 : 0
      for (let i = 0; i < this.totalCourses; i++) {
        if (i % this.limitPage === 0) {
          page++
          }
      }
      return page
    },
    /**
     * pagina de hoje vai começar a listagem de cursos
     */
    startPage: function () {
        return this.currentPage === 1 ? 0 : (this.currentPage * this.limitPage) - this.limitPage
    },

  },

  methods: {

    // ------------ REQUISIÇÕES ----------------
    /**
     * Metodo para buscar os cursos
     */
    getCourses: async function () {
      this.spinner = true
      const body = { start: this.startPage, limit: this.limitPage, sort: this.sortCourses, search: this.inputSearch  }
      await this.$tallos_academy_api.post('courses/list', body)
          .then(response => {
            this.courses = response.data.courses ? response.data.courses: []
            this.totalCourses = response.data.count ? response.data.count : 0 
          })
          .catch(() => {
            this.courses = []
            this.totalCourses = 0
            this.$toasted.global.default({msg: 'Não há cursos no momento.'})

          })
      this.spinner = false
    },

    // ------------------- GERAIS ------------------
    /**
     * Metodo para ativar ou desativar um curso
     */
    toggleActive: async function (id, event) {
      const statusToChange = event.target.checked;
      await this.$tallos_academy_api.put(`courses/${id}`, {
        active: statusToChange,
        updatedBy: this.$store.getters.admin.name
      })
        .then(response => {
          this.$toasted.global.defaultSuccess({msg: statusToChange ? 'O curso está ativado' : 'O curso está desativado'})
        })
        .catch(error => {
          this.$toasted.global.defaultError({msg: error.response.data.message})
        })
    },
    /**
     * Metodo que seta current page e atualiza cursos
     */
    setCurrentPage: async function(current){
      this.currentPage = current > 0 && current <= this.totalPages ? current : this.currentPage
      await this.getCourses()
    },

    getSearch: async function(){
      await this.getCourses()
      this.processing = false
    },

    // ---------- HELPERS ---------------
    /**
     * Metodo que traduz nome dos planos
     */
    translatePlans: function (plan) {
      switch (plan) {
        case 'basic':
          return 'Basic'
        case 'essential':
          return 'Essencial'
        case 'advanced':
          return 'Avançado'
        case 'professional':
          return 'Profissional'
      }
    }
  },

  created: function () {
    this.debouncedGetAnswer = lodash.debounce(this.getSearch, 500),
    document.title = "Tallos Admin - Academy";
  },

  beforeMount: async function () {
    await this.getCourses()
  }
}
</script>

<style scoped>
* {
  transition: .2s !important;
}

p, h5, h4, h3 {
  margin: 0;
  padding: 0;
}

.search-admin {
  position: absolute !important;
  top: 8px;
  right: 10px;
  cursor: pointer;
}

.card-custom {
  cursor: pointer;
}

.card-custom:hover {
  background-color: #eee;
}

.button-tag {
  vertical-align: middle;
  color: #007bff;
  font-size: .6rem;
  margin-left: 4px;
  padding: 2px 13px;
  border-radius: 10px;
  border: solid 1px #007bff;
}

.button-tag:hover {
  color: #fff;
  background-color: #007bff;

}

/* START: SWITCH */
.switch {
  display: flex;
  margin-top: 2px;
  position: relative;
  width: 51px;
  height: 23px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #007bff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #007bff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.tags{
  width: 120% !important;
  margin-top: 1.2em;
  
}

.tag{
  padding-bottom: 7px;
}

/* width */
::-webkit-scrollbar {
  height: 5px !important;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #dbdbdb; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b5b5b5; 
}

/* END: SWITCH */
</style>
